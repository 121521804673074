import {
  Box,
  CircularProgress,
  Card,
  CardContent,
  listItemClasses,
  Typography,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  Grid,
  Button,
  FormControl,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useCustom, useUpdate } from "@refinedev/core";
import { FundSnapshot } from "interfaces/fund-snapshot";
import { useEffect, useMemo, useState } from "react";
import { warm, neutral, primary } from "style/color";
import { getNextDay, getPreviousDay, shortFormat } from "utils/date-format";
import { moneyFormat } from "utils/money-format";

export const SnapshotCard: React.FC = () => {
  const [date, setDate] = useState<Date | null>(null);
  const [dateFilter, setDateFilter] = useState<string>(
    getPreviousDay(new Date())
  );
  const [snapshotsData, setSnapshotsData] = useState<FundSnapshot[]>([]);
  const { mutate, isLoading: recalculateIsLoading } = useUpdate();
  const {
    data: snapshots,
    isLoading,
    isSuccess,
    refetch,
  } = useCustom({
    url: `fund-snapshot`,
    method: "get",
    config: {
      filters: [
        {
          field: "filter[date]",
          operator: "eq",
          value: dateFilter,
        },
      ],
    },
  });
  const { data: locked } = useCustom({
    url: `fund-snapshot/locked`,
    method: "get",
  });
  const totals = useMemo(() => {
    let investmentsT = 0;
    let cashT = 0;
    let liquidT = 0;
    let gvT = 0;
    let aumT = 0;
    let aumFeeT = 0;
    let gvFeeT = 0;
    if (snapshotsData) {
      investmentsT = snapshotsData.reduce(
        (prev, next) => prev + next.investmentsTotal,
        0
      );
      cashT = snapshotsData.reduce((prev, next) => prev + next.cash, 0);
      liquidT = snapshotsData.reduce(
        (prev, next) => prev + next.totalLiquid,
        0
      );
      gvT = snapshotsData.reduce(
        (prev, next) => prev + next.grantvestmentsTotal,
        0
      );
      aumT = snapshotsData.reduce((prev, next) => prev + next.aum, 0);
      aumFeeT = snapshotsData.reduce((prev, next) => prev + next.aumFee, 0);
      gvFeeT = snapshotsData.reduce(
        (prev, next) => prev + next.grantvestmentFee,
        0
      );
    }

    return { investmentsT, cashT, liquidT, gvT, aumT, aumFeeT, gvFeeT };
  }, [snapshotsData]);
  const handleClick = (event: any) => {
    mutate(
      {
        resource: `fund-snapshot/calculate`,
        values: {
          date: getNextDay(locked?.data.date as Date),
        },
        id: "",
      },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };
  useEffect(() => {
    if (snapshots) {
      setSnapshotsData(
        (snapshots.data as FundSnapshot[])
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshots]);

  const handleChange = (tempDate: Date | null) => {
    if (tempDate) {
      setDate(tempDate);

      const day = `${tempDate.getDate()}-${tempDate.toLocaleDateString(
        "en-us",
        {
          month: "short",
        }
      )}-${tempDate.getUTCFullYear()}`;
      setDateFilter(day);
    } else {
      setDateFilter("");
    }
  };
  const getDate = () => {
    const nextDay = getNextDay(locked?.data.date as Date);
    return shortFormat(nextDay);
  };

  return isLoading ? (
    <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <Card sx={{ boxShadow: "none" }}>
      <CardContent>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={12} sm={4}>
            {locked && locked.data && (
              <Typography
                sx={{ color: neutral[600] }}
                variant="bodyCopyRegular"
              >
                Last Day Locked :{locked?.data.snapshotDay as string}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4} textAlign={"center"}>
            {locked && locked.data && (
              <Button
                disabled={recalculateIsLoading}
                startIcon={
                  recalculateIsLoading && (
                    <CircularProgress size={20} color="inherit" />
                  )
                }
                onClick={handleClick}
                color="primary"
                variant="contained"
              >
                Recalculate for {getDate()}
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sm={4} textAlign={"end"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl size="small" sx={{ py: 1.5 }}>
                <DatePicker
                  slotProps={{
                    textField: {
                      id: "date",
                      name: "date",
                    },
                  }}
                  label="Filter by date"
                  disableFuture={true}
                  format="MM/DD/YYYY"
                  name="dateOfBirth"
                  value={date}
                  onChange={(date) => handleChange(date && new Date(date))}
                />
              </FormControl>
            </LocalizationProvider>
          </Grid>
        </Grid>
        <TableContainer
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            mt: 2,
            [` & .${listItemClasses.root}:hover`]: {
              backgroundColor: warm[200],
              cursor: "pointer",
            },
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: primary[500] }}>Dates</TableCell>
                <TableCell sx={{ color: primary[500] }}>Fund Name</TableCell>
                <TableCell sx={{ color: primary[500] }}>Investments</TableCell>
                <TableCell sx={{ color: primary[500] }}>Cash</TableCell>
                <TableCell sx={{ color: primary[500] }}>Total Liquid</TableCell>
                <TableCell sx={{ color: primary[500] }}>GV</TableCell>
                <TableCell sx={{ color: primary[500] }}>AUM</TableCell>
                <TableCell sx={{ color: primary[500] }}>AUM Fee</TableCell>
                <TableCell sx={{ color: primary[500] }}>GV Fee</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ color: primary[500] }}></TableCell>
                <TableCell sx={{ color: primary[500] }}>Total</TableCell>
                <TableCell sx={{ color: primary[500] }}>
                  ${moneyFormat(totals.investmentsT)}
                </TableCell>
                <TableCell sx={{ color: primary[500] }}>
                  ${moneyFormat(totals.cashT)}
                </TableCell>
                <TableCell sx={{ color: primary[500] }}>
                  ${moneyFormat(totals.liquidT)}
                </TableCell>
                <TableCell sx={{ color: primary[500] }}>
                  ${moneyFormat(totals.gvT)}
                </TableCell>
                <TableCell sx={{ color: primary[500] }}>
                  ${moneyFormat(totals.aumT)}
                </TableCell>
                <TableCell sx={{ color: primary[500] }}>
                  ${moneyFormat(totals.aumFeeT)}
                </TableCell>
                <TableCell sx={{ color: primary[500] }}>
                  ${moneyFormat(totals.gvFeeT)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isSuccess &&
                snapshotsData.length !== 0 &&
                snapshotsData.map((snapshot: FundSnapshot) => (
                  <TableRow key={snapshot.snapshotId}>
                    <TableCell>{snapshot.snapshotDay}</TableCell>
                    <TableCell>{snapshot.fund.fundAccountName}</TableCell>
                    <TableCell>
                      ${moneyFormat(snapshot.investmentsTotal)}
                    </TableCell>
                    <TableCell>${moneyFormat(snapshot.cash)}</TableCell>
                    <TableCell>${moneyFormat(snapshot.totalLiquid)}</TableCell>
                    <TableCell>
                      ${moneyFormat(snapshot.grantvestmentsTotal)}
                    </TableCell>
                    <TableCell>${moneyFormat(snapshot.aum)}</TableCell>
                    <TableCell>${moneyFormat(snapshot.aumFee)}</TableCell>
                    <TableCell>
                      ${moneyFormat(snapshot.grantvestmentFee)}
                    </TableCell>
                  </TableRow>
                ))}
              {isLoading && (
                <Grid item xs={12} sm={10} textAlign={"center"} sx={{ m: 3 }}>
                  {" "}
                  <CircularProgress color="primary" />
                </Grid>
              )}
              {!isLoading && setSnapshotsData.length === 0 && (
                <Typography
                  sx={{ mt: 4, color: neutral[600] }}
                  variant="bodyCopyRegular"
                >
                  You don't have any snapshots.
                </Typography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};
